import React from 'react';

import Seo from '../components/seo';
import GatsbyGoogleTag from '../components/gatsby-google-tag';
import TritonDefaultLayout from '../layout/default-layout'
import TritonDefaultHomepage from '../components/homepage/homepage';

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => {
  return (
    <>
      <Seo />
      <GatsbyGoogleTag />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link rel="preload" as="style" href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&subset=latin&display=swap"></link>
      <link rel="stylesheet" media="print" href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&subset=latin&display=swap" />
      <noscript>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&subset=latin&display=swap" />
      </noscript>
    </>
  )
}

const IndexPage = (props) => {
  //console.debug('IndexPage - props', props);
  return (
    <TritonDefaultLayout children={<TritonDefaultHomepage />} />
  )
}
export default IndexPage
