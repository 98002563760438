import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import sections from "../sections/sections"
import Fallback from "../fallback"

export default function Homepage(props) {
  //console.debug('Homepage - props', props)

  //orchard
  const siteSectionDataHomepageQuery = useStaticQuery(graphql`
    query siteSectionDataHomepageQuery {
      siteData {
        landingPage {
          contentItems {
            ... on LandingPage {
              contentItemId
              contentType
              displayText
              sections {
                contentItems {
                  ...SectionHero
                  ...SectionHomepageOurSolutions
                  ...SectionCTA
                  ...SectionHomepageTestimonials
                  ...SectionBasic
                  ...SectionHomepageNews
                }
              }
            }
          }
        }
      }
    }
  `);

  if (typeof window !== 'undefined') {
    document.body.id = 'homepage';
  }

  //console.debug('Homepage - siteSectionDataHomepageQuery', siteSectionDataHomepageQuery)

  return (
    <>
      {siteSectionDataHomepageQuery.siteData.landingPage.contentItems[0].sections.contentItems.map((block) => {
        //console.debug('block', block)
        const { contentItemId, contentType, 
          //...componentProps 
        } = block
        //console.debug('sections[contentType]', sections[contentType])
        const Component = sections[contentType] || Fallback
        return <Component key={contentItemId} renderSource={'Homepage'} {...block} />
        //return <Component key={id} {...componentProps} />
      })}
    </>
  )
}
